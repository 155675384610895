import { createSlice } from '@reduxjs/toolkit';

type LoyaltyProgram = {
    level: string;
};

type Reward = {
    balance: number;
    total_earned: number;
    total_spent: number;
    expired: number;
};

type CustomerRewardsHistory = {
    date: string;
    comment: string;
    points: number;
    isNew?: boolean;
};

type SearchResultPageInfo = {
    page_size: number;
    current_page: number;
};

type CustomerRewardsHistoryOutput = {
    items: CustomerRewardsHistory[];
    page_info: SearchResultPageInfo;
    total_count: number;
};

type Customer = {
    isSelectCustomer: boolean;
    customerId?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    phone_number?: string;
    is_active?: boolean;
    loyalty_program?: LoyaltyProgram;
    rewards?: Reward;
    rewards_history?: CustomerRewardsHistoryOutput;
    customerAccumulation: any;
};
const initialState: Customer = {
    isSelectCustomer: false,
    customerId: '',
    customerAccumulation: [],
};
export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        updateCustomerInfo: (state, action) => {
            try {
                state.customerId = action.payload?.customerId;
                state.firstname = action.payload?.firstname;
                state.lastname = action.payload?.lastname;
                state.email = action.payload?.email;
                state.is_active = action.payload?.is_active;
                state.phone_number = action.payload?.phone_number;
                state.loyalty_program = action.payload?.loyalty_program;
                state.rewards = action.payload?.rewards;
                state.rewards_history = action.payload?.rewards_history;
                state.isSelectCustomer = true;
            } catch (error) {
                console.log(error);
            }
        },
        logoutCustomer: (state) => {
            state.isSelectCustomer = initialState.isSelectCustomer;
            state.customerId = '';
        },
        setCustomerAccumulation: (state, action) => {
            state.customerAccumulation = action.payload;
        },
    },
});

export const { logoutCustomer, updateCustomerInfo, setCustomerAccumulation } =
    customerSlice.actions;

export default customerSlice.reducer;
