export const ModalConfig = {
    icon: null,
    style: {
        backgroundColor: 'rgba(234, 222, 255, 1)',
        color: 'rgba(83, 45, 109, 1)',
        fontSize: 16,

        borderRadius: 16,
    },
    okButtonProps: {
        style: {
            backgroundColor: 'rgba(83, 45, 109, 1)',
            color: 'white',
            fontSize: 16,
            borderRadius: 8,
            width: 120,
            height: 40,
            fontFamily: 'Montserrat',
            cursor: 'pointer',
            border: 'none',
        },
    },
};
