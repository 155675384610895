import { BASE_ROUTER } from 'constants/router';
import { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from 'containers/index';
import { useSelector } from 'react-redux';
import type { RootState } from 'store';
import PrivateRoute from './PrivateRoute';
import { Page404 } from 'pages/404';
import { emitter } from 'graphql/client';
import { App } from 'antd';
import { useDispatch } from 'react-redux';
import { updateStatusLogout } from 'features/auth/authSlice';
import { ModalConfig } from 'components/atom/ModalConfig';
export const BaseRouter = () => {
    const { modal } = App.useApp();
    const dispatch = useDispatch();
    const [isNeedLogout, setIsNeedLogout] = useState(false);
    useEffect(() => {
        const handleLogoutError = () => {
            setIsNeedLogout(true);
            console.log('Handling logout error in graphql client');
            // Thực hiện các xử lý khác khi có lỗi logout
        };

        // Lắng nghe sự kiện logoutError
        emitter.on('logout', handleLogoutError);
    }, []);
    useEffect(() => {
        if (isNeedLogout) {
            modal.error({
                title: 'Error',
                content: 'Token đã hết hạn , vui lòng đăng nhập lại !',
                onOk: () => {
                    dispatch(updateStatusLogout());
                    setIsNeedLogout(false);
                },
                centered: true,
                ...ModalConfig,
            });
        }
    }, [isNeedLogout]);
    const { isLogged } = useSelector((state: RootState) => state.auth);
    return (
        <Suspense fallback={'loading'}>
            <Routes>
                <Route path={BASE_ROUTER.LOGIN} element={<Container.Login />} />
                <Route
                    path={BASE_ROUTER.HOME}
                    element={
                        <PrivateRoute isAuthenticated={isLogged}>
                            <Container.Home />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={BASE_ROUTER.NEW_USER}
                    element={
                        <PrivateRoute isAuthenticated={isLogged}>
                            <Container.NewUser />
                        </PrivateRoute>
                    }
                />
                <Route path={'*'} element={<Page404 />} />
            </Routes>
        </Suspense>
    );
};
